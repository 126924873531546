* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App {
  background-color: #fff;
  display: flex;
  flex: 1;
  position: relative;

  flex-direction: column;
  color: white;
}
p {
  color: #151515;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 200;
}

h1 {
  font-size: 64px;
  color: #fff;
}

.purple-header {
  font-size: 64px;
  color: rgba(84, 23, 67, 1);
}

.footerP {
  color: #fff;
  font-size: 16px;
  margin-left: 8px;
}

.nav-btns {
  color: rgba(161, 8, 59, 1);
  font-weight: 600;
  font-size: 16px;
}
.social-icons {
  cursor: pointer;
  color: #fff;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
}
